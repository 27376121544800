exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recommendations-js": () => import("./../../../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms_of_use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-tracking-js": () => import("./../../../src/pages/tracking.js" /* webpackChunkName: "component---src-pages-tracking-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-posts-archive-template-js": () => import("./../../../src/templates/postsArchiveTemplate.js" /* webpackChunkName: "component---src-templates-posts-archive-template-js" */)
}

