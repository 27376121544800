import { createGlobalStyle } from 'styled-components';

import DrukTextWideCyrMediumWoff from '../fonts/subset-DrukTextWideCyr-Medium.woff';
import DrukTextWideCyrMediumWoff2 from '../fonts/subset-DrukTextWideCyr-Medium.woff2';

import InterRegularWoff from '../fonts/subset-Inter-Regular.woff';
import InterRegularWoff2 from '../fonts/subset-Inter-Regular.woff2';
import InterMediumWoff from '../fonts/subset-Inter-Medium.woff';
import InterMediumWoff2 from '../fonts/subset-Inter-Medium.woff2';
import InterSemiBoldWoff from '../fonts/subset-Inter-SemiBold.woff';
import InterSemiBoldWoff2 from '../fonts/subset-Inter-SemiBold.woff2';

export const theme = {
    fonts: {
        main: 'Inter Intl, -apple-system, sans-serif, Arial',
        headers: 'Druk Text Wide Cyr, -apple-system, sans-serif, Arial',
    },
    colors: {
        primary: '#000000',
        secondary: '#2765ED',
        link: '#2765ED',
        active: '#16367B',
        light: '#F5FAFF',
        divider: '#E3E7ED',
        white: 'hsl(216, 16%, 94%)',
        dark: 'hsl(247, 67%, 10%)',
        dividerlight: 'hsl(243, 13%, 31%)',
        red: '#ED2762',
    },
    breakpoints: {
        mobile: 'only screen and (max-width: 576px)',
        tablet: 'only screen and (max-width: 768px)',
        tabletPlus: 'only screen and (max-width: 1024px)',
        notebook: 'only screen and (max-width: 1200px)',
        notebookPlus: 'only screen and (max-width: 1440px)',
    },
    animations: {
        linksColor: 'color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    },
    shadows: {},
};

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Inter';
        src: local('Inter Regular'), local('Inter-Regular'), 
            url(${InterRegularWoff2}) format('woff2'),
            url(${InterRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Inter';
        src: local('Inter Medium'), local('Inter-Medium'),
            url(${InterMediumWoff2}) format('woff2'),
            url(${InterMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Inter';
        src: local('Inter Semi Bold'), local('Inter-SemiBold'),
            url(${InterSemiBoldWoff2}) format('woff2'),
            url(${InterSemiBoldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Druk Text Wide Cyr';
        src: url(${DrukTextWideCyrMediumWoff2}) format('woff2'),
            url(${DrukTextWideCyrMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }
    
    body,html {
        
        background-color: hsl(0, 0%, 100%);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        color: ${({ theme }) => theme.colors.primary};
        font-family: ${({ theme }) => theme.fonts.main}!important;
        font-size: 16px;
        line-height: 16px;
    }
    body {
        background-color: hsl(0, 0%, 100%);
        pointer-events: inherit!important;
    }

    //Open Modal
    body.openModal {
        overflow: hidden;
    }
    
    //Swal
    .swal2-popup {
        border-radius: 1rem!important;
    }
    .swal2-title {
        font-size: 1.5rem!important;
        font-family: ${({ theme }) => theme.fonts.headers}!important;
        color: ${({ theme }) => theme.colors.link}!important;
        font-weight: normal!important;
    }
    .swal2-html-container {
        font-size: 1rem!important;
        color: ${({ theme }) => theme.colors.primary}!important;
    }
    .swal2-styled.swal2-confirm {
        border-radius: 100px!important;
        padding: 1.15rem 1.5rem!important;
        font-size: 1.15rem!important;
        line-height: 1.5rem!important;
    }
    .swal2-no-war {
        display: none!important;
    }
    .swal2-styled.swal2-confirm {
        background: ${({ theme }) => theme.colors.secondary}!important;
    }
    
    a {
        color: ${({ theme }) => theme.colors.link};
        text-decoration: none;
        transition: ${({ theme }) => theme.animations.linksColor};
    }
    a:hover {
        color: ${({ theme }) => theme.colors.active};
        text-decoration: underline;
    }

    //Headers
    h1 {
        font-family: ${({ theme }) => theme.fonts.headers};
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 1.5rem;
        color: ${({ theme }) => theme.colors.secondary};
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 1.25rem;
            margin-bottom: 0;
        }
        @media ${({ theme }) => theme.breakpoints.tabletPlus} {
            font-size: 2rem;
        }
    }
    h1.small {
        font-size: 2rem;
        margin-bottom: 2.5rem;
    }
    h2 {
        font-family: ${({ theme }) => theme.fonts.headers};
        color: ${({ theme }) => theme.colors.secondary};
        font-size: 2.5rem;
        line-height: 2.75rem;
        margin-bottom: 1.25rem;
        @media ${({ theme }) => theme.breakpoints.tablet} {
            font-size: 1.75rem;
            line-height: 2.25rem;
        }
    }
    h2.white {
        color: ${({ theme }) => theme.colors.white};
    }
    h3 {
        font-family: ${({ theme }) => theme.fonts.headers};
        font-size: 1rem;
        line-height: 1.25rem;
        color:${({ theme }) => theme.colors.secondary};
    }
    h4 {
        font-family: ${({ theme }) => theme.fonts.headers};
        font-size: 1rem;
        line-height: 1.25rem;
        color:${({ theme }) => theme.colors.secondary};
    }
    h5 {
        font-size: 1.25rem;
        line-height: 1.25rem;
        color:${({ theme }) => theme.colors.secondary};
    }
    @media ${({ theme }) => theme.breakpoints.notebook} {
        
    }
    @media ${({ theme }) => theme.breakpoints.tablet} {
        [data-aos^='fade'][data-aos^='fade'] {
            opacity: 100% !important;
        }
        
    }
    @media ${({ theme }) => theme.breakpoints.mobile} {
        [data-aos^='fade'][data-aos^='fade'] {
            opacity: 100% !important;
        }
        
    }
    p {
      line-height: 1.5rem;
    }
    
    //Animations
    [data-aos='fade-up-ee'] {
        opacity: 0;
        transition-property: transform, opacity;
        -webkit-transform: translateY(70px);
        transform: translateY(70px);
    }
    [data-aos='fade-left-ee'] {
        -webkit-transform: translate3d(70px, 0, 0);
        transform: translate3d(70px, 0, 0);
    }
    [data-aos='fade-right-ee'] {
        -webkit-transform: translate3d(-70px, 0, 0);
        transform: translate3d(-70px, 0, 0);
    }
    [data-aos='fade-up-right-ee'] {
        -webkit-transform: translate3d(-70px, 70px, 0);
        transform: translate3d(-70px, 70px, 0);
    }
    [data-aos][data-aos][data-aos-easing="cubic-bezier"],body[data-aos-easing="cubic-bezier"] [data-aos] {
        transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1.3);
    }
    
    //Forms
    .Form {
        margin-top:2rem;
    }
    .Form > div {
        position: relative;
    }
    .blockModalForm .hideForm {
        display: none
    }
    //Forms /Input
    .label {
        margin-bottom: 0.5rem;
    }
    .input {
        line-height: 1.5rem;
        color: ${({ theme }) => theme.colors.primary};
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius:0;
        padding: 1rem 0;
        display: block;
        width: 100%;
        margin-bottom: 2.25rem;
        outline: 0;
        transition: border-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    .input.grey {
        background-color: ${({ theme }) => theme.colors.white};
    }
    .input:focus {
        border-color: ${({ theme }) => theme.colors.secondary};
    }
    .input.isInvalid {
        border-color: ${({ theme }) => theme.colors.red};
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 18.5882L5.41174 4L3.99998 5.41176L18.5882 20L20 18.5882Z' fill='%23D21E4F'/%3E%3Cpath d='M5.41178 20L20 5.41174L18.5882 3.99998L4.00002 18.5882L5.41178 20Z' fill='%23D21E4F'/%3E%3C/svg%3E%0A");
        background-size: 16px 16px;
        background-position: right 0 center;
        background-repeat: no-repeat;
    }
    .input.isInvalid:focus {
        border-bottom: 2px solid ${({ theme }) => theme.colors.red};
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    //Forms /Select
    .input.select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1.25rem center;
        background-size: 16px 16px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    //Forms /Switch
    .form-check-group {
        margin-bottom: 1.25rem;
    }
    .form-check-group .header {
        margin-bottom: 1rem;
        font-weight: 500;
    }
    .form-check {
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5em;
        margin-bottom: 0.5rem;
    }
    .form-switch {
        padding-left: 2.5em;
    }
    .form-check .form-check-input {
        float: left;
    }
    .form-check label {
        line-height: 1.5rem;
    }
    .form-check-input {
        width: 1em;
        height: 1em;
        margin-top: .25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0,0,0,.25);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
    
    
    .form-check-input:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
    .form-switch .form-check-input {
        width: 2em;
        margin-left: -2.5em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
        background-position: left center;
        border-radius: 2em;
        transition: background-position .15s ease-in-out;
    }
    
    .form-switch .form-check-input:checked {
        background-position: right center;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
    //Forms /Checkbox
    .checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    .checkbox + label::before {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid ${({ theme }) => theme.colors.secondary};
        margin-right: 0.75rem;
        border-radius: 10rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    .checkbox.isInvalid + label::before {
        border: 2px solid ${({ theme }) => theme.colors.active};
        
    }
    .checkbox:checked + label::before {
        border-color: ${({ theme }) => theme.colors.link};
        background-color: ${({ theme }) => theme.colors.link};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    .checkbox:not(:disabled):not(:checked) + label:hover::before {
        border-color: ${({ theme }) => theme.colors.secondary};
    }
    //Forms /Misc
    .loading, .loadingAuth{
        position: absolute !important;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .loading img, .Form .loading img{
        width: 4rem;
        height: 4rem;
    }
    .error {
        position: absolute;
        bottom: 1.25rem;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.red};
    }
    .privacyAgreement {
        padding-bottom: 1.75rem;
        margin-bottom: 1.5rem;
    }
    
    //Modal
    .blockModalForm {
        top: 0;
        left: 0;
        background: rgba(12, 8, 41, 0.65);
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
        padding: 5rem 0;
    }
    .blockModalForm .wrapper {
        max-width: 1200px;
        margin: auto;
        position: relative;
        background: #fff;
        border-radius: 1.5rem;
        width: 90%;
        padding: 6rem 0;
    }
    
    
    @media ${({ theme }) => theme.breakpoints.tabletPlus} {
        [data-aos='fade-up-ee'] {
            -webkit-transform: none;
            transform: none;
        }
    }
    @media ${({ theme }) => theme.breakpoints.tablet} {
        [data-aos='fade-up-ee'] {
            
            -webkit-transform: none;
            transform: none;
        }
    }     
    @media ${({ theme }) => theme.breakpoints.mobile} {
        
        h1.small {
          font-size: 1.5rem;
        }
        .input.isInvalid {
            background-position: right 0 center;
        }
        
        //Animations
        [data-aos='fade-up-ee'] {
            opacity: 0;
            transition-property: transform, opacity;
            -webkit-transform: none;
            transform: none;
        }
        [data-aos='fade-left-ee'] {
            -webkit-transform: none;
            transform: none;
        }
        [data-aos='fade-right-ee'] {
            -webkit-transform: none;
            transform: none;
        }
        [data-aos='fade-up-right-ee'] {
            -webkit-transform: none;
            transform: none;
        }
        [data-aos][data-aos][data-aos-easing="cubic-bezier"],body[data-aos-easing="cubic-bezier"] [data-aos] {
            transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1.3);
        }
    }
`;
